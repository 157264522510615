import { mediaQueries, typography, size, Button } from '@everlywell/leaves';
import styled from 'styled-components';

export const Headline = styled.h2`
  ${typography.displayText}
  text-align: center;
  margin-top: ${size.xl3}px;
  margin-bottom: ${size.xl1}px;
  max-width: 440px;

  ${mediaQueries.forTabletVerticalUp} {
    font-size: 22.8px;
    line-height: ${size.xl1}px;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    ${typography.displayText}
    font-size: ${size.xl1}px;
    margin-top: 0;
    margin-bottom: ${size.xl2}px;
    text-align: left;
  }
`;

export const VideoContainer = styled.div`
  display: none;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  margin-top: -${size.xl1}px; /* video sits flush with top of section */
  position: relative;

  ${mediaQueries.forTabletVerticalUp} {
    display: block;
    margin-top: 0;
  }
  ${mediaQueries.forPhoneOnly} {
    display: block;
    margin-top: 0;
  }
`;

export const MobileVideoContainer = styled(VideoContainer)`
  display: block;

  ${mediaQueries.forTabletVerticalUp} {
    display: none;
  }
  ${mediaQueries.forPhoneOnly} {
    display: none;
  }
`;

export const Video = styled.iframe`
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const CTA = styled(Button)`
  width: 100%;
  max-width: 224px;
`;
