import {
  Col,
  colors,
  Container,
  Row,
  size,
  useHooks,
} from '@everlywell/leaves';
import SectionWrapper from 'components/SectionWrapper';
import { GatsbyImage as Img, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { animated as A, useSpring, config } from 'react-spring';
import { sanitize } from 'utils/domHelpers';

import * as S from './styles';

export interface TestimonialsSectionProps {
  ctaText?: string;
  ctaUrl?: string;
  description: {
    description: string;
  };
  videoLink?: string;
  image?: IGatsbyImageData;
}

export const TestimonialsSection: React.FC<TestimonialsSectionProps> = (
  props,
) => {
  const { ctaUrl, ctaText, description, videoLink, image } = props;

  const [testimonialVideoEl, videoInView] = useInView({ triggerOnce: true });

  const { isMobile } = useHooks.useWindowSize();

  // Video covers up section wrapper background and sits flush against above content on mobile
  const mobileWrapperMargin = isMobile ? `${size.xl1}px` : 0;

  const [callToActionRef, callToActionInView] = useInView({
    triggerOnce: true,
  });

  // triggered from scrolling into view
  const headlineTransition = useSpring({
    opacity: videoInView ? 1 : 0,
    transform: videoInView ? 'translateY(0)' : `translateY(${size.xl2}px)`,
    config: config.slow,
  });

  // triggered from scrolling into view
  const callToActionVisible = Boolean(ctaText && callToActionInView);
  const callToActionTransition = useSpring({
    opacity: callToActionVisible ? 1 : 0,
    transform: callToActionVisible
      ? 'translateY(0)'
      : `translateY(${size.xl2}px)`,
    config: config.slow,
  });

  return (
    <SectionWrapper
      ref={testimonialVideoEl}
      style={{ backgroundColor: colors.teal1, marginTop: mobileWrapperMargin }}
    >
      {videoInView && (
        <>
          {videoLink && (
            <S.MobileVideoContainer>
              <S.VideoContainer>
                <S.Video
                  data-testid="testimonial-video"
                  aria-hidden="true"
                  src={videoLink}
                  width={560}
                  height={315}
                  allow="autoplay; fullscreen"
                />
              </S.VideoContainer>
            </S.MobileVideoContainer>
          )}
          <Container>
            <Row middle="xs">
              {/* Reverse columns on mobile */}
              <Col last="xs" first="md" xs={12} md={5}>
                <Row center="xs" start="md">
                  <Col xs={12}>
                    <Row center="xs" start="md">
                      <div>
                        <A.div style={headlineTransition}>
                          <S.Headline>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: sanitize(description?.description),
                              }}
                            />
                          </S.Headline>
                        </A.div>
                      </div>
                    </Row>
                  </Col>
                  {ctaText && (
                    <Col xs={12}>
                      <Row center="xs" start="md">
                        <div ref={callToActionRef}>
                          <A.div style={callToActionTransition}>
                            <S.CTA appearance="secondary" href={ctaUrl}>
                              {ctaText}
                            </S.CTA>
                          </A.div>
                        </div>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col xs={12} md={7}>
                {videoLink && (
                  <S.VideoContainer>
                    <S.Video
                      data-testid="testimonial-video"
                      aria-hidden="true"
                      src={videoLink}
                      width={560}
                      height={315}
                      allow="autoplay; fullscreen"
                    />
                  </S.VideoContainer>
                )}
                {image && !videoLink && (
                  <Img
                    image={image}
                    style={{ width: '100%', height: '100%' }}
                    alt=""
                  />
                )}
              </Col>
            </Row>
          </Container>
        </>
      )}
    </SectionWrapper>
  );
};

export default TestimonialsSection;
