import React from 'react';
import { IGatsbyImage } from 'utils/types';

import { TestimonialsSection } from '../../../components/PDP/TestimonialsSection';

export interface TestimonialsContainerProps {
  content?: {
    cta?: {
      text: string;
      url: string;
    };
    description: {
      description: string;
    };
    listOfContentSections: {
      embeddedVideoLink: string;
      image: IGatsbyImage;
    }[];
  };
}

export const TestimonialsContainer: React.FC<TestimonialsContainerProps> = ({
  content,
}) => {
  if (!content) return null;

  const { cta, description, listOfContentSections } = content;
  const videoLink = listOfContentSections[0].embeddedVideoLink;
  const image = listOfContentSections[0].image;

  return (
    <TestimonialsSection
      ctaText={cta?.text}
      ctaUrl={cta?.url}
      description={description}
      videoLink={videoLink}
      image={image?.gatsbyImageData}
    />
  );
};

export default TestimonialsContainer;
